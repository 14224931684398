<template>
  <div id="all-ctegories">
    <div class="breadcrumb-con">
      <p>您的位置：</p>
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item
          :to="list.redirect"
          v-for="list in breadList"
          :key="list.redirect"
          v-show="list.meta.name"
          >{{ list.meta.name }}</el-breadcrumb-item
        >
      </el-breadcrumb>
      <svg-icon icon-class="ic-close" @click="$router.push('/Home')" />
    </div>
    <div class="all-ctegories-nav">
      <ul>
        <li
          v-for="(item, index) in Class"
          :key="index"
          @click="goCategory(index)"
        >
          <img v-lazy="item.categoryImage" alt="" :key="item.url" />
          <a>{{ item.categoryName }}</a>
        </li>
      </ul>
    </div>
    <div class="all-ctegories">
      <div class="menu" v-for="(menu, index) in CategoricalData" :key="index">
        <div class="title">
          <p class="nav-title">{{ menu.title }}</p>
          <span class="sub-title" @click.prevent="onNav(index)">
            (&nbsp;{{ menu.onTitle }}<svg-icon icon-class="ic-arrow-a" />)
            <ul v-show="subShow === index">
              <li
                class="sub"
                v-for="(list, idx) in menu.child"
                :key="idx"
                :title="list.categoryName"
                @click="clickSub(list.subItemList, list.categoryName, idx)"
              >
                {{ list.categoryName }}
              </li>
            </ul>
          </span>
        </div>
        <div class="content">
          <p v-for="(a, i) in menu.onChild" :key="i">
            <a @click="goCategory(index, i)">{{ a.categoryName }}</a>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "all-ctegories",
  data() {
    return {
      onNavNum: null,
      //二级菜单显示
      subShow: -1,
      //分类数据
      CategoricalData: [],
      Class: "",
      subIndex: 0,
    };
  },
  computed: {
    breadList() {
      let breadList = this.$route.matched;
      console.log(breadList);
      return breadList;
    },
  },
  created() {
    // 获取分类数据遍历
    this.getAllClass();
  },
  methods: {
    push(content) {
      this.$router.push({
        name: "category-list",
        params: { content: content },
      });
    },
    // 点击二级菜单改变菜单名和内容
    clickSub(list, title, index) {
      this.CategoricalData[this.onNavNum].onTitle = title;
      this.CategoricalData[this.onNavNum].onChild = list;
      this.subIndex = index;
    },
    //二级菜单显示隐藏
    onNav(index) {
      this.onNavNum = index;
      if (this.subShow >= 0) {
        this.subShow = index;
        this.subShow = -1;
      } else {
        this.subShow = index;
      }
    },
    async getAllClass() {
      const data = await this.$API.homePage.getAllClass({
        object: {
          enterpriseId: "",
        },
      });
      this.Class = data.data.data.categoriesCatalog;
      localStorage.setItem("category", JSON.stringify(this.Class));
      this.CategoricalData = this.Class.map((item) => {
        return {
          title: item.categoryName,
          // eTitle: item.eTitle,
          onTitle:
            item.subItemList == null ? "" : item.subItemList[0].categoryName, //默认第0个
          onChild:
            item.subItemList == null ? "" : item.subItemList[0].subItemList,

          // onTitle:item.subItemList[0].categoryName, //默认第0个
          // onChild: item.subItemList[0].subItemList,
          child: item.subItemList,
        };
      });
    },
    //跳转分类
    goCategory(index, i) {
      let breadcrumb = [];
      let idx = this.subIndex;
      breadcrumb.push(index);
      if (i != undefined) {
        breadcrumb.push(idx)
        breadcrumb.push(i);
      }
      this.$router.push({
        name: "category-list",
        query: {
          breadcrumb: JSON.stringify(breadcrumb),
        },
      });
    },
  },
};
</script>


<style lang="scss" scoped>
#all-ctegories {
  display: flex;
  flex-direction: column;
  align-items: center;
  border-top: var(--subjectColor) 1px solid;
}
.breadcrumb-con {
  width: 1200px;
  height: 55px;
  display: flex;
  align-items: center;
  p {
    font-size: 14px;
    color: #999;
  }
  ::v-deep.el-breadcrumb {
    span {
      color: #999;
    }
    .el-breadcrumb__inner {
      font-weight: normal;
    }
    .el-breadcrumb__inner:hover {
      color: var(--subjectColor);
    }
  }
  ::v-deep.el-breadcrumb .el-breadcrumb__item:last-child span {
    color: #333;
  }
  .svg-icon {
    margin-left: 4px;
    font-size: 12px;
    color: #333;
    cursor: pointer;
  }
  .svg-icon:hover {
    color: #000;
  }
}
.all-ctegories-nav {
  width: 1200px;
  margin-bottom: 20px;
  background: #fff;

  ul {
    display: flex;
    flex-wrap: wrap;
    li {
      width: 120px;
      height: 122px;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding-top: 16px;
      border-right: 1px solid #f5f5f5;
      border-bottom: 1px solid #f5f5f5;
      cursor: pointer;
      img {
        width: 60px;
        height: 60px;
      }
      a {
        font-size: 14px;
        line-height: 20px;
        color: #333333;
        margin-top: 10px;
      }
    }
    li:nth-child(10n) {
      border-right: none;
    }
    li:hover {
      box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.08);
    }
  }
}
.all-ctegories {
  background: #fff;
  width: 1200px;
  padding: 8px 20px;
  margin-bottom: 20px;
  .menu {
    margin-bottom: 10px;
    display: flex;
    flex-direction: column;
    .title {
      display: flex;
      align-items: center;
      border-bottom: 1px solid var(--subjectColor);
      margin-bottom: 16px;
      .nav-title {
        // height: 18px;
        margin: 8px 0;
        border-left: 5px solid var(--subjectColor);
        color: var(--subjectColor);
        font-size: 18px;
        text-indent: 12px;
        font-weight: 600;
      }
      .sub-title {
        margin-left: 10px;
        font-size: 14px;
        color: var(--subjectColor);
        cursor: pointer;
        position: relative;
        user-select: none;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        .svg-icon {
          width: 10px;
          margin: 0 4px;
        }
        ul {
          position: absolute;
          top: 20px;
          left: -2px;
          width: 100px;
          background: #ffffff;
          border: 1px solid #dddddd;
          box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.12);
          opacity: 1;
          z-index: 10;
          color: #666;
          li {
            width: 98px;
            height: 28px;
            line-height: 28px;
            text-indent: 8px;
            padding-right: 8px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
          li:hover {
            color: var(--subjectColor);
            background: #f5f5f5;
          }
        }
      }
    }
    .content {
      display: flex;
      flex-wrap: wrap;
      width: 1160px;
      p {
        width: 193px;
        margin-bottom: 10px;

        a {
          font-size: 12px;
          color: #888888;
          cursor: pointer;
        }
        a:hover {
          color: var(--subjectColor);
        }
      }
    }
  }
}
</style>